import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'en',
  regionISO: 'US',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: 'GTM-NP5PF2JM',
  googleAnalyticsId: '',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: 'f582dd4d-1b26-4290-950a-fa5a241b5891',
  footerLogoId: '530113a8-4fa6-4189-b2ab-76a03ebc7e6a',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap',
  defaultTitle: 'yes2games',
  titleTemplate: 'or-site-yes2games | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      strategy: 'afterInteractive',
      'data-domain-script': '018e2d9a-2b0d-70e4-a99a-f3ef9126cf39'
    },
    {
      id: 'ot-callback-script',
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            function OptanonWrapper() {
            };
          `
      }
    },
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
    {
      id: 'gtm-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', 'GTM-NP5PF2JM');
          `
      }
    },
    {
      src: '//dsh7ky7308k4b.cloudfront.net/publishers/yes2gamescom.min.js',
      strategy: 'beforeInteractive',
    },
    {
      src: '//btloader.com/tag?o=5184339635601408&amp;upapi=true',
      strategy: 'beforeInteractive',
    },
    {
      src: 'https://pm.azerioncircle.com/p/focus/',
      strategy: 'afterInteractive',
    },
  ],
  links: [
  ],
};